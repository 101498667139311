<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>{{ situationDict.situationType === 'LIVE_SITUATION' ? 'Жизненная ситуация' : 'Бизнес-ситуация' }}
            «{{ situationDict.situationName }}»</h1>


          <v-row>

            <v-col cols="12" lg="3">
              <v-text-field v-model="situationValue.cznSystemNumber" label="№ карточки"></v-text-field>
            </v-col>


            <v-col cols="12" lg="3">
              <v-menu
                  ref="requestDateMenu"
                  v-model="requestDateMenu"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="situationValue.requestDate | dateFormat"
                      append-icon="mdi-calendar"
                      label="Дата запроса"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="situationValue.requestDate"
                    locale="RU"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      @click="menu = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="3">
              <v-text-field v-model="situationValue.specialistFullName" label="Инициалы специалиста"></v-text-field>
            </v-col>

            <v-col cols="12" lg="3">
              <v-select v-model="situationValue.status"
                        :items="situationStatuses"
                        item-text="name"
                        item-value="code"
                        label="Статус"
              ></v-select>
            </v-col>

          </v-row>

          <div class="services-list">
            <template v-for="(serviceValue, index1) in situationValue.serviceValues">
              <SituationRow
                  v-if="serviceValue.serviceName == null"
                  :key="'sli'+index1"
                  :service-value="serviceValue"
              ></SituationRow>
            </template>
            <template v-for="(serviceValue, index2) in situationValue.serviceValues">
              <SituationRow
                  v-if="serviceValue.serviceName != null"
                  :key="'sliunt'+index2"
                  :service-value="serviceValue"
              ></SituationRow>
            </template>
          </div>
          <a href="#" @click.prevent="addUntypedService">Добавить нетиповую услугу</a>

          <br>
          <div v-if="editable" class="save-buttons">
            <v-btn outlined @click="$router.push('/situations/')">отмена</v-btn>
            <v-btn color="primary" @click="submit">Сохранить изменения</v-btn>
          </div>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import SituationRow from "./elements/SituationRow";

export default {
  name: "SituationInfo",
  props: {},
  components: {SituationRow},
  data() {
    return {
      date: null,
      menu: null,
      errorText: null,
      apiLoaded: false,
      situationDict: null,
      requestDateMenu: null,
      editable: false,
      situationStatuses: [
        {code: "IN_PROGRESS", name: "В процессе"},
        {code: "FINISHED", name: "Завершено"}
      ],
      situationValue: null
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    async loadSituationDict(situationId) {
      let req = await this.$getApi("/situations/getSituationsDict")
      if (req.ok) {
        let situationsList = req.payload
        this.situationDict = this.extractSituation(situationsList, situationId)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    extractSituation(situationsList, situationId) {
      for (let situation of situationsList)
        if (situation.id === +situationId) {
          situation.serviceDict.sort((a, b) => (a.id > b.id) ? 1 : -1)
          return situation
        }
    },

    async loadSituationValue(id) {
      let req = await this.$getApi("/situations/getSituationValue", {id: id})
      if (req.ok) {
        this.situationValue = req.payload
        // console.log(this.situationValue)
        this.situationValue.serviceValues.sort((a, b) => {
          if(a.serviceInDict == null)
            return -1
          return (a.serviceInDict.id > b.serviceInDict.id) ? 1 : -1
      })
        this.situationDict = this.situationValue.situationInDict
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    initializeEmptySituation() {
      this.situationValue = {
        id: null,
        userName: this.$user.username,
        situationInDict: this.situationDict,
        cznSystemNumber: null,
        requestDate: null,
        specialistFullName: null,
        status: 'IN_PROGRESS',
        serviceValues: []
      }
      for (let s of this.situationDict.serviceDict) {
        this.situationValue.serviceValues.push({
          id: null,
          serviceInDict: s,
          serviceName: null,
          plannedToProvide: false,
          plannedToProvideDate: null,
          provided: false,
          provideDate: null,
          comment: null
        })
      }
    },

    async submit() {
      // console.log(this.situationValue)
      let t = this.validate()
      if (t !== true) {
        this.errorText = t
        return
      }

      let req = await this.$postApi("/situations/saveSituationValue", this.situationValue)
      if (req.ok) {
        this.$router.push("/situations")
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
      }
    },

    addUntypedService(){
      this.situationValue.serviceValues.push({
        id: null,
        serviceInDict: null,
        serviceName: "",
        plannedToProvide: false,
        plannedToProvideDate: null,
        provided: false,
        provideDate: null,
        comment: null
      })
    },

    validate() {
      if (this.situationValue.cznSystemNumber == null || this.situationValue.cznSystemNumber.length == null)
        return "Номер карточки учета должен быть указан!"
      return true
    },
  },

  async beforeMount() {
    this.operation = this.$route.params.operation
    this.editable = (this.$user.role==='ROLE_CZN_MANAGER' || this.$user.role==='ROLE_CZN_TERRITORIAL')
    if (this.operation === 'new') {
      this.situationId = this.$route.params.id
      await this.loadSituationDict(this.situationId)
      this.initializeEmptySituation()
    } else {
      await this.loadSituationValue(this.$route.params.id)
      // console.log(this.situationValue)
    }


    this.apiLoaded = true
  }
};
</script>
