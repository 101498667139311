<template>
  <div class="services-list-item">
    <div class="services-list-item-left">
      <h2>Услуга</h2>
      <p v-if="serviceValue.serviceName == null">{{ serviceValue.serviceInDict.serviceName }}</p>
      <p v-else>
        <v-textarea v-model="serviceValue.serviceName"></v-textarea>
      </p>

    </div>
    <div class="services-list-item-right">
      <v-row>
        <v-col cols="6">
          <v-checkbox
              v-model="serviceValue.plannedToProvide"
              label="Планируется"
          ></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox
              v-model="serviceValue.provided"
              label="Получена"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-menu
              min-width="auto"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="serviceValue.plannedToProvideDate | dateFormat"
                  append-icon="mdi-calendar"
                  readonly
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="serviceValue.plannedToProvideDate"
                no-title
                scrollable
                locale="RU"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
              min-width="auto"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="serviceValue.provideDate | dateFormat"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  readonly
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="serviceValue.provideDate"
                no-title
                scrollable
                locale="RU"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea v-model="serviceValue.comment" label="Примечание"></v-textarea>
        </v-col>
      </v-row>

    </div>
  </div>


</template>

<script>
export default {
  name: "SituationRow",
  props: ["serviceValue"],
  data: function () {
    return {
      menu: false
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  }
}
</script>

<style scoped>

</style>